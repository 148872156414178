import type {ReactElement, Ref} from 'react';
import React, {useContext, useEffect, useRef, useState} from 'react';
import type {PanelContentProps, PanelFooterProps, PanelHeaderProps} from '@Components/panel';
import {PanelContent} from '@Components/panel';
import type {BasePanel, PanelGrowlData, PanelMessageProp} from '@Components/panel/panel.types';
import {PANEL_MESSAGE_TYPE, PanelDataKeys} from '@Components/panel/panel.types';
import {Icon} from '@Components/icon-v2';
import {Text, TextSize, TextType} from '@Components/text';
import {IconSize} from '@Components/icon-v2/icon.types';
import {ModalDetails} from '@Components/modal/modal-context';
import {getUniqueString} from '@Utils/string.util';
import {DEFAULT_ANIMATION_DURATION} from '@Components/modal';
import styles from './panel.module.scss';
import {useAppSelector} from '@/hooks';

interface PanelProps extends Partial<BasePanel> {
  panelHeader?: ReactElement<PanelHeaderProps> | null;
  panelContent?: ReactElement<PanelContentProps>;
  panelFooter?: ReactElement<PanelFooterProps> | null;
  className?: string;
  panelHeight?: string;
  panelWidth?: string;
  panelMessage?: PanelMessageProp;
  panelBodyClassName?: string;
}

export const Panel = React.forwardRef(
  (
    {
      className = '',
      panelWidth = '100%',
      panelHeight = '100%',
      panelHeader = null,
      panelContent = <PanelContent key="placeholder-context" />,
      panelFooter = null,
      panelMessage = {type: PANEL_MESSAGE_TYPE.NONE, message: ''} as PanelMessageProp,
      panelBodyClassName = '',
      ...props
    }: PanelProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const modalDetails = useContext(ModalDetails);
    const panelContainerRef = useRef<HTMLDivElement>(null);
    const id = modalDetails?.panelId ? modalDetails.panelId : (props.panelId ?? getUniqueString());
    const isPanelHidden = useAppSelector((state) => {
      return !!state.panels.panelHashmap[id]?.isHidden;
    });
    const [hidePanel, setHidePanel] = useState(false);
    const growlData: PanelGrowlData | undefined = useAppSelector((state) => {
      if (state.panels.panelHashmap[id]?.data && PanelDataKeys.GROWL in state.panels.panelHashmap[id].data) {
        return state.panels.panelHashmap[id].data[PanelDataKeys.GROWL] as PanelGrowlData;
      }
      return undefined;
    });

    const isOpenedInModal = (): boolean => {
      if (!panelHeader) {
        return false;
      }

      if (panelHeader.props.isOpenedInModal !== undefined) {
        return panelHeader.props.isOpenedInModal;
      }

      return modalDetails?.isMobileModal === true;
    };

    const isHeaderTitleCenterAligned = (): boolean => {
      if (!panelHeader) {
        return false;
      }

      if (panelHeader.props.centerAlignTitle !== undefined) {
        return panelHeader.props.centerAlignTitle;
      }

      return modalDetails?.isMobileModal === true;
    };

    const getClassNameForPanelMessage = (): string => {
      switch (panelMessage.type) {
        case PANEL_MESSAGE_TYPE.NONE:
          return '';
        case PANEL_MESSAGE_TYPE.INFO_NEUTRAL:
          return styles.infoNeutral;
        case PANEL_MESSAGE_TYPE.INFO_PRIMARY:
          return styles.infoPrimary;
        case PANEL_MESSAGE_TYPE.WARNING:
          return styles.warning;
        case PANEL_MESSAGE_TYPE.ERROR:
          return styles.error;
        case PANEL_MESSAGE_TYPE.SUCCESS:
          return styles.success;
        default:
          return '';
      }
    };

    const getIconForPanelMessage = (): string => {
      switch (panelMessage.type) {
        case PANEL_MESSAGE_TYPE.NONE:
          return '';
        case PANEL_MESSAGE_TYPE.INFO_NEUTRAL:
        case PANEL_MESSAGE_TYPE.INFO_PRIMARY:
        case PANEL_MESSAGE_TYPE.WARNING:
          return 'icon-info';
        case PANEL_MESSAGE_TYPE.ERROR:
          return 'icon-exclamation-triangle';
        case PANEL_MESSAGE_TYPE.SUCCESS:
          return 'icon-check-circle';
        default:
          throw new Error(`Unhandled panel message type: ${panelMessage.type}`);
      }
    };

    const getPanelMessage = (): ReactElement | null => {
      if (panelMessage.type !== PANEL_MESSAGE_TYPE.NONE) {
        return (
          <div className={`${styles.messageContainer} ${getClassNameForPanelMessage()} flex-center spacing-p-t-2 spacing-p-b-2`}>
            <Icon className={`spacing-m-r-2 ${styles.panelMessageIcon}`} size={IconSize.SIZE_ICON_20} icon={getIconForPanelMessage()} />
            <Text className={styles.panelMessageText} val={panelMessage.message} size={TextSize.XSMALL} />
          </div>
        );
      }
      return null;
    };

    const getHiddenClass = (): string => {
      return hidePanel ? '_hidden' : '';
    };

    const getMessageGrowl = (): ReactElement => {
      const isGrowlDataValid = growlData && Object.keys(growlData).length > 0;
      return (
        <div
          className={`spacing-p-t-1 spacing-p-r-3 spacing-p-b-1 spacing-p-l-3 radius-round flex-row-align-center ${styles.messageGrowl} ${isGrowlDataValid ? styles.visible : ''}`}
        >
          {isGrowlDataValid ? <Icon className={`content-body-white`} icon={growlData.icon} size={IconSize.SIZE_ICON_16} /> : null}
          {isGrowlDataValid ? <Text className={`content-body-white spacing-m-l-1 ${styles.growlText}`} type={TextType.BODY} size={TextSize.XSMALL} val={growlData.text} /> : null}
        </div>
      );
    };

    useEffect(() => {
      if (isPanelHidden) {
        setTimeout(() => {
          setHidePanel(true);
        }, DEFAULT_ANIMATION_DURATION);
      } else {
        setHidePanel(false);
      }
    }, [isPanelHidden]);

    return (
      <div
        key={props.panelId}
        className={`${className} flex-center ${getHiddenClass()} ${growlData ? styles.growlParentContainer : ''}`}
        ref={ref}
        style={{
          width: panelWidth,
          height: panelHeight,
        }}
      >
        {getMessageGrowl()}
        <div ref={panelContainerRef} className={`${styles.content} ${panelBodyClassName} flex-v-row`}>
          {panelHeader
            ? React.cloneElement(panelHeader, {
                centerAlignTitle: isHeaderTitleCenterAligned(),
                isOpenedInModal: isOpenedInModal(),
              })
            : null}
          {getPanelMessage()}
          {React.cloneElement(panelContent, {
            className: `${panelContent.props.className} ${styles.panelContent}`,
          })}
          {panelFooter}
        </div>
      </div>
    );
  }
);
